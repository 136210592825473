import { FC } from 'react';
import Page, { PageProps } from '../../components/page';
import { Box } from '@mui/material';
import Icon from '../../components/icon';
import Text from '../../components/text';
import LoginForm from './login-form';
import PageCard from '@/components/page-card';

const LoginPage: FC<PageProps> = () => {
  return (
    <Page>
      <PageCard>
        <Box m={0.5}>
          <Icon name="lock" background="first" />
        </Box>
        <Text size="x-large">Sign in</Text>
        <Box mt={6} width={400} minHeight={260}>
          <LoginForm />
        </Box>
      </PageCard>
    </Page>
  );
};

export default LoginPage;
