import { FC } from 'react';
import { AuthUser } from '../../lib/services/auth.service';
import { Avatar, Box, Theme } from '@mui/material';
import Tooltip from '../tooltip';
import { getFirstLastName } from '../../lib/helpers';
import Icon from '../icon';
import { createUseStyles } from 'react-jss';

interface Props {
  user: AuthUser;
}

const useStyles = createUseStyles((theme: Theme) => ({
  userBadge: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const UserBadge: FC<Props> = ({ user }) => {
  const styles = useStyles();
  const { displayName, email, photoURL } = user;
  const username = displayName || '';
  const { first, last } = getFirstLastName(username);
  const hasPhoto = Boolean(photoURL);
  const hasName = Boolean(first) || Boolean(last);

  const TooltipEl = (
    <>
      {username}
      {Boolean(username) && <br />}
      {email}
    </>
  );

  return (
    <Box>
      <Tooltip title={TooltipEl} placement="right">
        {hasPhoto && (
          <Avatar
            alt={username}
            src={photoURL || ''}
            imgProps={{ referrerPolicy: 'no-referrer' }}
          />
        )}
        {!hasPhoto && hasName && (
          <Avatar className={styles.userBadge}>
            {first[0]}
            {last[0]}
          </Avatar>
        )}
        {!hasPhoto && !hasName && (
          <Avatar className={styles.userBadge}>
            <Icon name="person" color="white" />
          </Avatar>
        )}
      </Tooltip>
    </Box>
  );
};

export default UserBadge;
